<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">
          {{ $t('auth---Welcome, {username}!', { username: username }) }}
        </template>
        <template v-else>{{ $t('auth---Welcome!') }}</template>
      </h2>

      <p
          v-if="isAzLms()"
          class="b-text _note _center"
      >
        {{
          $t('auth---Contact an administrator to recover your password.')
        }}
      </p>

      <p v-else class="b-text _note _center">
        {{
          $t('auth---Contact an branch curator to recover your password')
        }}
      </p>

      <div class="b-form _wide">
        <div
            v-if="isAzLms()"
            class="b-row _centered _space_above _space_below"
        >
          <a
              href="https://s.algoritmika.org/dtjsxz"
              target="_blank"
              class="b-button"
          >
            <span class="b-icon _office-file-xls_fff _m_right"></span>
            <span>{{ $t('auth---List of administrators') }}</span>
          </a>
        </div>
        <div class="b-row _centered _space_above">
          <button class="b-link" @click="$router.push({ name: 'auth-log-in' })">
            {{ $t('auth---I remembered my password') }}
          </button>
        </div>
      </div>
    </div>
  </Outer>
</template>

<i18n src="../i18n/translations/reset.json"></i18n>

<script>
import Outer from '../components/Outer.vue';
import {mapState} from 'vuex';

const LMS_AZ_HOSTNAME = 'lms.algoritmika.az';
export default {
  components: {
    Outer,
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$i18n.t('auth---I forgot everything');
  },
  computed: {
    ...mapState({
      username: (state) => state.username,
    }),
  },
  methods: {
    isAzLms() {
      return LMS_AZ_HOSTNAME === location.hostname;
    }
  }
};
</script>
